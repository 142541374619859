<template>
  <div>
    <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
      <span
        slot="id"
        slot-scope="text"
      >{{text}}</span>
      <span slot="status" slot-scope="record"> {{ record.active ? 'Активный' : 'Не активный' }}</span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$refs.editModal.open(record.id)">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
        <a-popconfirm
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="remove(record.id)"
        >
          <a href="javascript: void(0);" class="btn btn-sm btn-light">
            <small>
              <i class="fe fe-trash mr-2 pb-2" />
            </small>
            Удалить
          </a>
        </a-popconfirm>
      </span>
    </a-table>
    <edit-promotions-modal ref="editModal" @getList="$emit('getList')"/>
  </div>
</template>

<script>
import EditPromotionsModal from '@/components/functional/promotions/EditPromotionsModal'

export default {
  name: 'PromotionsTable',

  components: {
    EditPromotionsModal,
  },

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          dataIndex: 'ru.title',
        },
        {
          title: 'Cтатус',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Количество уровней',
          dataIndex: 'count_level',
        },
        {
          title: 'Количество бонусных',
          dataIndex: 'bonus_level',
        },
        {
          title: 'Период(дни)',
          dataIndex: 'days',
        },
        {
          title: 'Цена(грн)',
          dataIndex: 'price',
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },

  methods: {
    async remove(id) {
      try {
        await this.$services.delete(`admin/tariff_level/${id}`)
        this.$emit('getList')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
